<template>
  <div class="place-page container-fluid container-nopad" v-if="placeData">

    <div class="place-view">
      <div class="head d-flex flex-column justify-content-between" ref="head">
        <div class="back-block">
          <router-link :to="{path: this.$route.query?.back == 'list' ? '/place' : '/place/map'}" class="button-back-empty">
            <span class="fa fa-arrow-left"></span>
          </router-link>
        </div>
        <div class="me-3 pb-3">
          <span class="price-block float-end">
            <span class="fa fa-circle-o"></span>
            {{ this.getPrice(placeData.price) }}{{ this.getCurrencySymbol() }}
          </span>
        </div>
      </div>
      <div class="container-fluid">
        <div class="distance" v-if="placeData?.distance">
          {{ this.getFormatDistance(placeData.distance) }}
        </div>
        <div class="title">
          {{ placeData.title }}
        </div>
        <div class="work-time">
          <span class="fa fa-clock-o icon clock"></span>
          <span class="work-time-text" v-html="this.getFormatText(this.decode(placeData.workTime))"></span>
        </div>
        <div class="address">
          <span class="fa fa-map-marker icon"></span>
          <span v-html="placeData.address"></span>
        </div>
        <div class="phone" v-if="placeData.phone">
          <span class="fa fa-phone icon"></span><a :href="'tel:' + placeData.phone" target="_blank">{{ placeData.phone }}</a>
        </div>
        <div class="site" v-if="placeData.site">
          <span class="icon-img"><img src="~@/assets/img/icon-site-2.png" /></span>
          <a :href="placeData.site" target="_blank">{{ placeData.site }}</a>
        </div>

        <div class="social" v-if="placeData.facebookUrl || placeData.instagramUrl">
          <a :href="placeData.facebookUrl" class="fa fa-facebook" target="_blank" v-if="placeData.facebookUrl"></a>
          <a :href="placeData.instagramUrl" class="fa fa-instagram" target="_blank" v-if="placeData.instagramUrl"></a>
        </div>

        <div class="text" v-if="text">
          <span v-html="text"></span>
          <span v-if="isLongText && !isWrapText">... <a href="#" class="black-link" @click.prevent="updatePlaceTextWrap()">More</a></span>
          <span v-if="isLongText && isWrapText">&nbsp;<a href="#" class="black-link" @click.prevent="updatePlaceTextWrap()">Hide</a></span>
        </div>

        <div class="tag-list" v-if="placeTagLinkList.length > 0">
          <div class="caption">Features</div>
          <span class="tag" v-for="placeTagLinkData in placeTagLinkList" :key="placeTagLinkData.id">
            {{ placeTagLinkData.title }}
          </span>
        </div>

        <div class="image-list" v-if="placeImageList.length > 0">
          <div class="caption">Gallery</div>
          <div class="col-lg-6 offset-lg-3 col-12">
            <swiper
                :navigation="true"
                :modules="modules"
                :loop="true"
                :pagination="{clickable: true}"
                class="mySwiper">
              <swiper-slide v-for="(placeImageData) in placeImageList" :key="placeImageData.id">
                <img :src="this.getAppUrl(this.getPlaceImageUrl(placeImageData))" class="image" />
              </swiper-slide>
            </swiper>
          </div>
        </div>

      </div>
    </div>

  </div>

</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css';
// import {useWindowSize} from 'vue-window-size';
import PlaceMixin from '@/modules/place/mixins/PlaceMixin';
import BaseMixin from "@/modules/base/mixins/BaseMixin";

import {Pagination, Navigation} from 'swiper';

export default {

  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },

  mixins: [PlaceMixin, BaseMixin],

  data: () => ({
    placeData: {},
    text: '',
    isLongText: false,
    isWrapText: false,
    placeTagLinkList: [],
    placeImageList: [],
    placeLogoImageList: {
      logo_xs: null,
      logo_sm: null,
      logo_md: null,
      logo_lg: null
    },

    windowWidth: 0
  }),

  async mounted() {

    if (!await this.checkUser())
      return;

    await this.initGeoLocation(false);

    await this.doPlaceView();

    // const {width, height} = useWindowSize();
    // this.windowWidth = width;

    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    this.setHeaderStyle();
  },

  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },

  watch: {
    windowWidth: {
      handler() {

        this.setHeaderStyle();
      },
      deep: true
    }
  },

  methods: {

    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    setPlaceLogoImageList(placeLogoImageList) {

      if (!placeLogoImageList)
        return;

      placeLogoImageList.forEach((placeLogoImageData) => {

        this.placeLogoImageList[placeLogoImageData.type] = placeLogoImageData;
      });
    },

    async setHeaderStyle() {

      let image = require('../../../assets/img/place-head-bg-2.jpg');
      if (this.placeLogoImageList.logo_xs)
        image = this.getAppUrl(this.getPlaceImageUrl(this.placeLogoImageList.logo_xs));

      if (this.windowWidth >= 768 && this.placeLogoImageList.logo_sm)
        image = this.getAppUrl(this.getPlaceImageUrl(this.placeLogoImageList.logo_sm));

      if (this.windowWidth >= 1024 && this.placeLogoImageList.logo_md)
        image = this.getAppUrl(this.getPlaceImageUrl(this.placeLogoImageList.logo_md));

      if (this.windowWidth >= 1360 && this.placeLogoImageList.logo_lg)
        image = this.getAppUrl(this.getPlaceImageUrl(this.placeLogoImageList.logo_lg));

      this.$refs['head'].style.background = '#FFF url("' + image + '") no-repeat top center';
      this.$refs['head'].style.backgroundSize = 'cover';
    },

    async doPlaceView() {

      let apiHolder = await this.sendApiGet(['api/place/view/' + this.$route.params?.id]);

      if (!apiHolder.isSuccess())
        return;

      if (apiHolder.data?.placeData)
        this.placeData = apiHolder.data.placeData;
      if (apiHolder.data?.placeTagLinkList)
        this.placeTagLinkList = apiHolder.data.placeTagLinkList;
      if (apiHolder.data?.placeImageList)
        this.placeImageList = apiHolder.data.placeImageList;

      this.setPlaceLogoImageList(apiHolder.data?.placeLogoImageList);

      if (this.placeData)
        this.placeData.distance = this.getPlaceDistance(this.placeData);

      this.updatePlaceText();
    },

    updatePlaceText() {

      let text = this.placeData?.text;
      if (!text)
        return;

      if (this.isWrapText) {

        this.text = this.getFormatText(this.decode(text));
        return;
      }

      const limit = 600;
      if (text.length > limit)
        this.isLongText = true;

      text = text.substring(0, limit);
      this.text = this.getFormatText(this.decode(text));
    },

    updatePlaceTextWrap() {

      this.isWrapText = !this.isWrapText;
      this.updatePlaceText();
    }
  }

}
</script>

<style scoped>

.place-view .head {
  background: #FFF no-repeat top center;
  background-size: cover;
  min-height: 290px;
  margin-bottom: 10px;
}

.back-block {
  padding-left: 12px;
  padding-top: 16px;
}

.place-view .title {
  color: #3F414E;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-top: 5px;
}

.place-view .price-block {
  background: #FFFA83;
  border-radius: 38px;
  font-size: 26px;
  font-weight: 700;
  padding: 1px 15px 2px 7px;
}

.place-view .price-block .fa {
  font-size: 36px;
  position: relative;
  top: 2px;
}

.place-view .distance {
  font-size: 13px;
  float: right !important;
  font-weight: 500;
}

.place-view .icon {
  margin-right: 5px;
  color: #2CDAE5;
  width: 20px;
  font-size: 18px;
  text-align: center;
  position: relative;
}

.place-view .icon-img {
  margin-right: 5px;
  width: 20px;
  text-align: center;
  display: inline-block;
}

.place-view .text {
  padding-top: 10px;
}

.place-view .social {
  padding-top: 10px;
}

.place-view .social a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-size: 22px;
  line-height: 18px;
  text-decoration: none;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background: #A1A4B2;
  color: #FFF;
}

.place-view .social a.fa-facebook {
  background: #01479c;
}

.place-view .social a.fa-instagram {
  background: #9c0156;
}

.place-view .caption {
  margin-top: 20px;
  color: #3F414E;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.place-view .tag-list .tag {
  background: #343434;
  border-radius: 38px;
  color: #2CDAE5;
  font-weight: 500;
  padding: 3px 12px 5px 12px;
  margin: 0 5px 5px 0;
  display: inline-block;
}

.image-list {
  padding-bottom: 30px;
}

.image {
  max-width: 100%;
  width: 100%;
}

.black-link {
  font-weight: bold;
  text-decoration: none !important;
  color: #000 !important;
}

.clock {
  margin-top: 3px;
}

.work-time {
  display: flex;
}

</style>